/* ChecklistForm.css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
  }
  
  .checklist-form {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }

  .instructions {
    background-color: #fff3cd;
    color: #856404;
    padding: 12px;
    border-left: 4px solid #ffcc00;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  .instructions p {
    margin: 0;
    line-height: 1.5;
  }
  
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .section-title {
    font-size: 20px;
    font-weight: bold;
    color: #444;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .checklist-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    padding: 5px 0;
    flex: 1 1 45%;
  }
  
  .checkbox-item label {
    margin-left: 8px;
    color: #333;
  }
  
  .submit-button {
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    transition: background-color 0.3s ease;
  }

  .clear-button {
    background-color: hsl(0, 59%, 50%);
    color: white;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .signature-container {
    position: relative;
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 10px;
  }
  
  .signature-canvas {
    display: block;
    margin: 0 auto;
  }
  
  .signature-hint {
    text-align: center;
    color: #888;
    font-size: 0.9rem;
    margin-top: 5px;
  }
  