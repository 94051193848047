/* General Layout */
.report-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .report-card {
    background: white;
    width: 600px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title */
  .report-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Section */
  .report-section {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  /* Table */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background: #28a745;
    color: white;
  }
  
  /* Button */
  .download-button {
    display: block;
    width: 100%;
    background: #28a745;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
  }
  
  .download-button:hover {
    background: #218838;
  }
  