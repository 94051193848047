/* Global Styles */
*,
*::before,
*::after {
  box-sizing: border-box; /* Consistent box-sizing to prevent overflow */
  margin: 0;
  padding: 0;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  touch-action: none;              /* Prevent scroll hijack */
  -webkit-overflow-scrolling: auto; /* Higher z-index to ensure it's above other elements */
}

.close-modal {
  font-size: 28px;
  line-height: 1;
  padding: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10001;
  touch-action: manipulation; 
}


.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px; 
  max-height: 90vh;
  overflow-y: auto;
  z-index: 10000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  position: relative;
  -webkit-transform: translateZ(0); /* Force GPU layer on iOS */
  -webkit-overflow-scrolling: touch;
}

/* Responsive modal adjustments */
@media (max-width: 768px) {
  .modal-content {
      width: 95%;
      padding: 15px;
  }
}
/* Prevent horizontal scroll on mobile */
html, body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.job-list-header h1 {
  font-size: 2rem;
  color: #007820;
  margin: 0;
  flex: 1;
}

/* Main Job List Container */
.job-list-container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 40px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden; /* Prevent container overflow */
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #c82333;
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Full-width Search Input */
.search-container {
  margin-bottom: 1.5rem;
}

.search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: #fafafa;
}

.search-input:focus {
  outline: none;
  border-color: #007820;
  box-shadow: 0 0 5px rgba(0, 120, 32, 0.3);
}

body.modal-open {
  overflow: hidden;
  position: fixed; /* Optional but good for mobile scrolling lock */
  width: 100%;
  pointer-events: none;
}


/* Job Grid */
.job-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%; /* Ensure full width */
  max-width: 100%;
}

/* Job Card */
.job-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Header and Date */
.job-header h3 {
  font-size: 1.35rem;
  margin-bottom: 0.5rem;
  color: #007820;
}

.job-header p {
  font-size: 1rem;
  color: #777;
}

.date {
  color: #999;
  font-size: 0.95rem;
}

/* Image Grid for Thumbnails */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin: 20px 0;
  width: 100%; /* Full width for image grid */
}

.image-container img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 6px;
  transition: transform 0.2s ease;
}

.image-container:hover img {
  transform: scale(1.05);
}

/* Services and Notes Sections */
.services-section, .notes-section {
  margin-top: 20px;
  max-width: 100%;
}

.services-section h4, .notes-section h4 {
  margin-bottom: 8px;
  font-weight: 600;
  color: #444;
}

.services-section li {
  background: #e0f4ea;
  color: #007820;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 5px;
}

/* Button Group */
.button-container {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
}

.button-container button {
  flex: 1;
  padding: 12px 0;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.email-button {
  background-color: #28af60;
  color: white;
}

.email-button:hover, .view-details-button:hover {
  background-color: #219653;
}

.view-details-button {
  background-color: #28af60;
  color: white;
}

.push-to-quickbase-button {
  background-color: #28af60;
  color: white;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .job-list-container {
    max-width: 100%;
    padding: 0 20px;
  }

  .job-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .job-list-container {
    padding: 0 15px;
  }

  .job-grid {
    grid-template-columns: 1fr;
  }

  .job-card {
    padding: 18px;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .button-container button {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .job-list-container {
    padding: 0 10px;
  }

  .search-input {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  .job-card {
    padding: 15px;
    min-height: 250px;
  }

  .job-header h3 {
    font-size: 1.2rem;
  }

  .date {
    font-size: 0.85rem;
  }

  .button-container button {
    padding: 10px 0;
    font-size: 0.9rem;
  }

  .image-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
